<template>
	<div>
		<s-crud
			title="Defectos Descarte"
			:config="config"
			:filter="filter"
			add
			@save="save($event)"
			edit
			remove
			search-input
		> 
			<template v-slot="props">
				<v-container>
					<v-row>
						<v-col cols="6" lg="6" md="6" sm="12">
							<s-select-definition
								label="Tipo Cultivo"
								v-model="props.item.TypeCultive"
								:def="1173"
							></s-select-definition>
						</v-col>
						<v-col cols="6" lg="6" md="6" sm="12">
							<s-text
								label="Nombre del daño"
								v-model="props.item.DdcName"
								ref="textDdcName"
							></s-text>
						</v-col>
					</v-row>
				</v-container>
				</template>

			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col>
							<s-select-definition
								label="Tipo Cultivo"
								v-model="filter.TypeCultive"
								:def="1173"
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sDiscardDefectCultiveService from "@/services/FrozenProduction/DiscardDefectCultiveService.js"

	export default {
		data() {
			return {
				config: {
					model: {
						DdcID: "ID"
					},
					service: _sDiscardDefectCultiveService,
					headers: [
						{text: "ID", value: "DdcID"},
						{text: "Tipo de Cultivo", value: "TypeCultiveName"},
						{text: "Defecto", value: "DdcName"},,
					]
				},
				filter: { TypeCultive: 0}
			}
		},

		methods: {
			save(item) {
				if(item.DdcName == "")
				{
					this.$fun.alert("Ingrese el nombre", "warning");
					this.$refs.textDdcName.focus();
					return;
				}

				item.save();
			}
		},
	}
</script>